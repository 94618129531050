import axios from "axios";

export default {
    async getOre(dipendente_id: string) {
        const data = new FormData();
        data.append("where[banca_ore_dipendente]", dipendente_id.toString());
        data.append("orderby", "banca_ore_data");
        data.append("orderdir", "desc");

        const response = await axios.post(`rest/v1/search/banca_ore`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response.data.data;
    },

    async getBancaOre(dipendente_id: string) {
        const data = new FormData();
        data.append("dipendente_id", dipendente_id);

        //const response = await axios.post(`http://192.168.11.141/firegui_crm_h2/modulo-hr/app/bancaOre`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}modulo-hr/app/bancaOre`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },
};
