import axios from "axios";

export default {
    async getMagazzini() {
        const data = new FormData();
        data.append("orderby", "magazzini_titolo");
        data.append("orderdir", "asc");

        const response = await axios.post(`rest/v1/search/magazzini`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async saveMovimento(magazzino_id: string, tipo_movimento: string, causale: string, user: string, prodotti: Array<string>) {
        const data = new FormData();
        data.append("magazzino_id", magazzino_id);
        data.append("tipo_movimento", tipo_movimento);
        data.append("causale", causale);
        data.append("user", user);
        data.append("prodotti", JSON.stringify(prodotti));

        //const response = await axios.post(`http://192.168.11.141/firegui_crm_h2/magazzino/app/save`, data, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}magazzino/app/save`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async getCausali(tipo_movimento: string) {
        const data = new FormData();
        data.append("where[movimenti_causali_tipologia_movimento]", `${tipo_movimento}`);
        data.append("orderby", "movimenti_causali_id");
        data.append("orderdir", "asc");

        const response = await axios.post(`rest/v1/search/movimenti_causali`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async getMovimenti(userId: string) {
        const data = new FormData();
        data.append("where[movimenti_user]", `${userId}`);
        data.append("orderby", "movimenti_data_registrazione");
        data.append("orderdir", "DESC");

        const response = await axios.post(`rest/v1/search/movimenti`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },
};
