<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio programma</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- QRCode modal -->
                <Transition name="fade-transition">
                    <div v-if="showQrCode" class="modal_straordinario">
                        <div class="input_container">
                            <div class="title_qr">Inquadra il codice QR per timbrare</div>
                            <div class="qr_code">
                                <qr-stream @decode="onDecode" class="mb">
                                    <div class="frame"></div>
                                </qr-stream>
                            </div>

                            <div class="confirmation_text">
                                {{ confirmationText }}
                            </div>

                            <button @click="handleQrCodeTimbratura" class="btn_scan_qrcode" :disabled="disabledQrCodeButton">Salva</button>
                            <button @click="chiudiQrCode" class="btn_close_scan_qrcode">Chiudi</button>
                        </div>
                    </div>
                </Transition>

                <!-- NFC modal -->
                <Transition name="fade-transition">
                    <div v-if="nfcState.showNfcContainer" class="modal_nfc">
                        <div class="nfc_container">
                            <div class="title_nfc">Pronto per la scansione</div>
                            <div class="svg_container">
                                <img src="assets/nfc_icon.svg" alt="" />
                            </div>

                            <div class="help_text">
                                Avvicina il Tag NFC
                            </div>
                            <button @click="chiudiNfcContainer()" class="btn_chiudi_nfc">Annulla</button>
                        </div>
                    </div>
                </Transition>

                <!-- Lightbox Modale -->
                <Transition name="fade-transition">
                    <div v-if="lightboxOpen" class="lightbox_modal">
                        <div class="lightbox_content">
                            <img :src="currentImageUrl" class="lightbox_image" />
                            <div class="lightbox_controls">
                                <button @click="prevImage()" :disabled="currentIndex === 0">Indietro</button>
                                <button @click="nextImage()" :disabled="currentIndex === currentAttachmentFiles.length - 1">
                                    Avanti
                                </button>
                                <button @click="closeLightbox()" class="close_lightbox">Chiudi</button>
                            </div>
                        </div>
                    </div>
                </Transition>

                <div class="data_container">
                    <div class="appuntamento_title">
                        {{ appuntamento.projects_name }}
                    </div>
                    <div class="field">
                        <div class="title">Cliente</div>
                        <div class="value">
                            {{ riferimentoCliente(appuntamento) }}
                        </div>
                    </div>
                    <div v-if="appuntamento.projects_city && appuntamento.projects_address" class="field">
                        <div class="title">Indirizzo</div>
                        <div class="value">
                            <a
                                :href="generateMapsLink(appuntamento)"
                                target="_blank"
                                class="mobile_link"
                            >
                                <span>{{ appuntamento.projects_address }}, {{ appuntamento.projects_city }}</span>
                            </a>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data</div>
                        <div class="value">
                            {{ dateFormat(appuntamento.appuntamenti_giorno) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Orari</div>
                        <div class="value">
                            {{ `${appuntamento.appuntamenti_ora_inizio} - ${appuntamento.appuntamenti_ora_fine}` }}
                        </div>
                    </div>
                    <div class="field field_list">
                        <div class="title">Persone</div>
                        <div class="persone" v-for="(persona, index) in appuntamento.appuntamenti_persone" :key="index">
                            <div>{{ persona }}</div>
                        </div>
                    </div>
                    <div v-if="appuntamento.appuntamenti_automezzi" class="field field_list">
                        <div class="title">Automezzi</div>
                        <div class="automezzi" v-for="(automezzo, index) in appuntamento.appuntamenti_automezzi" :key="index">
                            <div>{{ automezzo }}</div>
                        </div>
                    </div>
                    <div v-if="appuntamento.appuntamenti_note" class="field field_description">
                        <div class="title">Note</div>
                        <div class="value">
                            {{ appuntamento.appuntamenti_note ? `${appuntamento.appuntamenti_note}` : "-" }}
                        </div>
                    </div>

                    
                    <div v-if="!rapportinoDaPresenza" style="width: 100%;">

                        <div v-if="appuntamento.projects_richiedi_timbratura && appuntamento.projects_richiedi_timbratura == '0'" style="width: 100%;">
                            <div v-if="!rapportinoCreato" class="container_button">
                                <div class="btn_action" @click="newRapportino()">Crea rapportino</div>
                            </div>
                            <div v-else class="existing_rapportino">Rapportino già creato</div>
                        </div>


                        <div
                            v-if="!rapportinoCreato && appuntamento.projects_richiedi_timbratura && appuntamento.projects_richiedi_timbratura == '1' && isAppointmentToday"
                            class="container_button"
                        >
                        <button
                                v-if="appuntamento.projects_timbra_nfc && appuntamento.projects_timbra_nfc == '1'"
                                class="btn_action"
                                @click="nfcScan()"
                            >
                                Timbra con NFC
                            </button>
                        </div>

                        <div
                            v-if="!rapportinoCreato && appuntamento.projects_richiedi_timbratura && appuntamento.projects_richiedi_timbratura == '1' && isAppointmentToday"
                            class="container_button"
                        >
                            <button
                                v-if="appuntamento.projects_timbra_gps && appuntamento.projects_timbra_gps == '1' && !geoNotAllowed"
                                class="btn_action"
                                @click="timbraGPS()"
                                :disabled="sendingCoordinates"
                            >
                                {{ buttonText }}
                            </button>
                        </div>

                        <div
                            v-if="!rapportinoCreato && appuntamento.projects_richiedi_timbratura && appuntamento.projects_richiedi_timbratura == '1' && isAppointmentToday"
                            class="container_button"
                        >
                        <button
                                v-if="appuntamento.projects_timbra_qrcode && appuntamento.projects_timbra_qrcode == '1'"
                                class="btn_action"
                                @click="openQrCode()"
                            >
                                Timbra con QR
                            </button>
                        </div>

                    </div>

                    <div v-if="appuntamento.projects_permetti_ordine_materiali && appuntamento.projects_permetti_ordine_materiali == '1'" class="container_button">
                        <div class="btn_action" @click="ordinaMateriali()">Ordina materiali</div>
                    </div>

                    <div
                        v-if="appuntamento.projects_richiedi_timbratura && appuntamento.projects_richiedi_timbratura == '1' && !isAppointmentToday"
                        class="container_button"
                    >
                        <div class="info_box">
                            La timbratura può essere effettuata solo nella giornata dell'appuntamento
                        </div>
                    </div>
                </div>

                <div v-if="services && services.length != 0" class="data_container services_container">
                    <div class="title">Servizi da svolgere</div>
                    <div v-for="(service, index) in services" :key="index" class="box_services">
                        <div v-if="service.gestione_servizi_servizi && service.gestione_servizi_servizi.length != 0">
                            <div v-for="(servizio, index) in service.gestione_servizi_servizi" :key="index" class="single_service">
                                {{ servizio }}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div v-if="products && products.length != 0" class="data_container products_container">
                    <div class="title">Prodotti associati</div>
                    <div v-for="(product, index) in products" :key="index" class="single_prodotto" @click="openProductDetail(product)">
                        <span>{{ product.fw_products_name }}</span>
                        <span v-if="product.project_products_quantity && product.project_products_quantity > 0">
                            {{ `(${product.project_products_quantity})` }}
                        </span>
                    </div>
                </div>

                <div v-if="attachments && attachments.length != 0" class="data_container services_container">
                    <div class="title">File commessa</div>
                    <div v-for="(attachment, index) in attachments" :key="index" class="box_services">
                        <div class="single_attachment">
                            <div class="attachment_title">{{ attachment.project_attachments_title }}</div>
                            <div v-if="attachment.project_attachments_file" class="attachments_file_container">
                                <div v-for="(file, index) in JSON.parse(attachment.project_attachments_file)" :key="index" class="attachment_file">
                                    <template v-if="isImage(file.client_name)">
                                        <img
                                            :src="setAttachmentUrl(file)"
                                            @click="openLightbox(index, attachment)"
                                            class="preview_image"
                                        />
                                    </template>
                                    <template v-else>
                                        <a :href="setAttachmentUrl(file)" target="_blank" class="mobile_link">
                                            {{ file.client_name }}
                                        </a>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";
import { arrowBackOutline, arrowForwardCircle } from "ionicons/icons";

import { defineComponent, ref, reactive, toRefs, onMounted, computed } from "vue";
import moment from "moment";
import { QrStream } from "vue3-qr-reader";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import NuovoRapportinoAppuntamento from "@/components/rapportini/NuovoRapportinoAppuntamento.vue";
import DettaglioRapportino from "@/components/rapportini/DettaglioRapportino.vue";
import ModalNuovoOrdine from "../ordini/ModalNuovoOrdine.vue";
import ModalNuovoOrdineAppuntamento from "../ordini/ModalNuovoOrdineAppuntamento.vue";
import DettaglioProdotto from "@/components/programma_lavoro/DettaglioProdotto.vue";
import NuovoRapportinoPresenza from "@/components/rapportini/NuovoRapportinoPresenza.vue";

import apiProdotti from "@/services/prodotti";
import apiProgetti from "@/services/progetti";
import apiPresenze from "@/services/presenze";

export default defineComponent({
    name: "ProgrammaLavoroDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
        QrStream,
    },
    setup(props, context) {
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;
        const loading = ref(false);
        const appuntamento = ref([]);
        appuntamento.value = { ...props.data };
        //console.log(appuntamento.value);
        
        const rapportinoCreato = ref(appuntamento.value.rapportino_creato);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };
        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(flag, res) {
            if (flag) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        function generateMapsLink(appuntamento) {
            const city = appuntamento.projects_city || "";
            const address = appuntamento.projects_address || "";
            const province = appuntamento.projects_province || "";
            
            if (!city || !address) {
                return ""; // Se city o address sono vuoti, restituisce una stringa vuota
            }
            
            return `https://maps.google.com/?q=${encodeURIComponent(`${city} ${address} ${province}`.trim())}`;
        }



        /**
         * 
         * ! Open rapportino detail
         * 
         */
        async function openRapportinoDetail(rapportino) {
            const modal = await modalController.create({
                component: DettaglioRapportino,
                componentProps: {
                    data: rapportino,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //Posso aver aperto questa pagina solo dopo aver creato un rapportino
                //quindi marco nuovamente il rapporitno come creato
                rapportinoCreato.value = true;
            });
            return modal.present();
        }


        /**
         * 
         * ! Open order modal
         * 
         */
        async function ordinaMateriali() {
            const modal = await modalController.create({
                component: ModalNuovoOrdineAppuntamento,
                componentProps: {
                    data: appuntamento.value,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //console.log(detail.data);
                    openToast("Ordine salvato con successo", "toast_success");
                }
            });
            return modal.present();
        }


        /**
         * ! Print correct customer info
         */
        const riferimentoCliente = (cliente) => {
            if (cliente.customers_company) {
                return cliente.customers_company;
            } else if (cliente.customers_name && !cliente.customers_last_name) {
                return `${cliente.customers_name}`;
            }
            return `${cliente.customers_name} ${cliente.customers_last_name}`;
        };

        /**
         * ! Format datetime to DD/MM/YY HH:mm
         */
        function dateTimeFormat(dateTime) {
            if (dateTime && moment.isDate(new Date(dateTime))) {
                return moment(dateTime).format("DD/MM/YYYY HH:mm");
            } else {
                return "-";
            }
        }

        /**
         * ! Load prodotti
         */
        const products = ref([]);
        const loadingProducts = ref(false);
        async function loadProducts() {
            try {
                const res = await apiProdotti.getProdottiCommessa(appuntamento.value.appuntamenti_impianto);
                if (res.status === 200 && res.data.status === 0) {
                    products.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei prodotti", "toast_danger");
                }
            } catch (error) {
                openToast("Si è verificato un errore durante la richiesta dei prodotti", "toast_danger");
            } finally {
                loadingProducts.value = false;
            }
        }

        /*******************************************************
         *
         * ! Mostra servizi associati alla commessa dell'appuntamento visualizzato
         * ? 09/05/2024 - Aggiunto filtro in base alla giornata dell'appuntamento dato che i servizi possono esssere associati a singole giornate
         *
         ******************************************************/
        const loadingServices = ref(false);
        const services = ref([]);
        let gestione_servizi = [];
        async function loadServices() {
            loadingServices.value = true;

            // Ottengo n° giorno settimana (1 = Lunedì, 2 = Martedì, ..., 7 = Domenica) rispetto alla data dell'appuntamento
            const dayNumber = moment(appuntamento.value.appuntamenti_giorno).isoWeekday();

            try {
                const res = await apiProdotti.getFilteredServizi(appuntamento.value.appuntamenti_impianto, dayNumber);
                //console.log(res);
                
                if (res.status === 200 && res.data.status === 0) {
                    services.value = res.data.data;

                    gestione_servizi = res.data.data.flatMap(function(item) {
                        return Object.entries(item.gestione_servizi_servizi).map(function([key, value]) {
                            return { id: key, name: value };
                        });
                    });
                    //console.log(gestione_servizi);
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei servizi", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta dei servizi", "toast_danger");
            } finally {
                //console.log(customers.value);
                loadingServices.value = false;
            }
        }


        /*******************************************************
         *
         * ? 06/12/2024
         * ! Load project attachments based on visibility
         *
         ******************************************************/
        const attachments = ref([]);
        const loadingAttachments = ref(false);
        async function loadAttachments() {
            try {
                const res = await apiProgetti.getCommessaAttachments(appuntamento.value.appuntamenti_impianto);
                if (res.status === 200 && res.data.status === 0) {
                    attachments.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta della documentazione", "toast_danger");
                }
            } catch (error) {
                openToast("Si è verificato un errore durante la richiesta della documentazione", "toast_danger");
            } finally {
                loadingAttachments.value = false;
            }
        }

        /*******************************************************
         * 
         * ! Open modal to create new rapportino
         * 
         ******************************************************/
        async function newRapportino() {
            const modal = await modalController.create({
                component: NuovoRapportinoAppuntamento,
                componentProps: {
                    data: appuntamento.value,
                    services: gestione_servizi,
                },
            });
            
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //openDetail(detail.data);
                    rapportinoCreato.value = true;
                    //closeModalOnSubmit(true, detail.data);
                    openRapportinoDetail(detail.data);
                    openToast("Rapportino salvato con successo", "toast_success");
                }
            });

            return modal.present();
        }

        
        /*******************************************************
         * 
         * 
         * ! Project attachments
         * 
         * 
         ******************************************************/
        const lightboxOpen = ref(false);
        const currentIndex = ref(0);
        const currentAttachmentFiles = ref([]);
        const currentAttachment = ref(null);

        const isImage = (filename) => {
                return /\.(jpe?g|png|gif|bmp|webp|svg)$/i.test(filename);
            };

        const currentImageUrl = computed(() => {
            return setAttachmentUrl(currentAttachmentFiles.value[currentIndex.value]);
        });

        const openLightbox = (fileIndex, attachment) => {
            currentAttachment.value = attachment;
            currentAttachmentFiles.value = JSON.parse(attachment.project_attachments_file).filter((file) =>
                isImage(file.client_name)
            );        
            
            currentIndex.value = fileIndex;
            lightboxOpen.value = true;
        };

        const closeLightbox = () => {
            lightboxOpen.value = false;
        };

        const prevImage = () => {
            if (currentIndex.value > 0) currentIndex.value--;
        };

        const nextImage = () => {
            if (currentIndex.value < currentAttachmentFiles.value.length - 1) currentIndex.value++;
        };

        function setAttachmentUrl(attachment) {            
            return `${process.env.VUE_APP_BASE_URL}uploads/${attachment.path_local}`;
        }


    /*******************************************************
     *
     * ! Open modal to display product info
     *
     ******************************************************/
        async function openProductDetail(product) {
        const modal = await modalController.create({
            component: DettaglioProdotto,
            componentProps: {
                prodotto: product,
            },
        });
        return modal.present();
        }


        /*******************************************************
         *
         * ! Open modal to create new rapportino after presenza
         *
         ******************************************************/
        const chiusoSenzaCreazionePresenza = ref(false);
        const rapportinoDaPresenza = ref(false);

        async function openNuovoInterventoFromPresenza(presenza, appuntamento) {
            let presenza_rapportino = {
                presenza: presenza,
                appuntamento: appuntamento,
            };

            localStorage.setItem("presenza_rapportino", JSON.stringify(presenza_rapportino));

            const modal = await modalController.create({
                component: NuovoRapportinoPresenza,
                componentProps: {
                    presenza: presenza,
                    appuntamento_ref: appuntamento,
                    services: gestione_servizi,
                },
            });

            modal.onDidDismiss().then(async (detail) => {
                if (detail.data && detail.data != undefined) {
                    //console.log(detail.data);
                    if(detail.data.chiusoSenzaPresenza) {
                        // Chiuso modale senza creare anche il rapportino, devo nascondere i pulsanti per non fare altre timbrature
                        rapportinoDaPresenza.value = true;
                    } else {
                        // Chiusura post creazione rapportino
                        openToast("Rapportino salvato con successo", "toast_success");
                        // Modifico presenza inserendo rapportini_id
                        const rapportino = detail.data.rapportino;
                        const presenza = detail.data.presenza;
    
                        if(rapportino && presenza && rapportino.rapportini_id && presenza.presenze_id) {
                            // rapportini_presenza
                            const res = await apiPresenze.addRapportino(presenza.presenze_id, rapportino.rapportini_id);
                            if (res.status === 200 && res.data.status === 0) {
                                // Svuoto dato nel localstorage
                                // console.log('Salvataggio andato a buon fine');
                                localStorage.removeItem("presenza_rapportino");
                            } else {
                                console.log(res);
                                
                                openToast("Si è verificato un errore in fase di collegamento tra presenza e rapportino, contattare l'assistenza", "toast_danger");
                                return;
                            }
                        }
                        // Svuoto presenza_rapportino
                        presenza_rapportino = null;
                        // Nascondo pulsanti
                        rapportinoCreato.value = true;
                    }
                }
            });

            return modal.present();
        }


        /*******************************************************
         * 
         * 
         * ! Gestione timbratura con QR Code
         * 
         * ? CONTROLLO presenze
         * * Se trovo almeno una presenza di oggi senza data fine vuol dire che sto timbrando l'uscita (posso averne fatte altre oggi ma sempre entrando ed uscendo)
         * * Se non la trovo vuol dire che sto timbrando l'entrata (o è la prima/unica della giornata o sto entrando nuovamente)
         ******************************************************/
        const presenza = ref(null);


        const confirmationText = ref("");
        const state = reactive({
            data: null,
        });
        const showQrCode = ref(false);
        const disabledQrCodeButton = ref(false);

        function onDecode(data) {
            state.data = data;
            if (data) {
                const time = moment().format("HH:mm");
                confirmationText.value = `Cliccando su "Salva" confermi di timbrare alle ore ${time}`;
            }
        }

        function openQrCode() {
            showQrCode.value = true;
        }
        function chiudiQrCode() {
            showQrCode.value = false;
            state.data = null;
            confirmationText.value = "";
            disabledQrCodeButton.value = false;
        }
        async function handleQrCodeTimbratura() {
            const cliente = appuntamento.value.appuntamenti_cliente;
            const commessa = appuntamento.value.appuntamenti_impianto;

            if (state.data) {
                let qrValue = state.data;
                disabledQrCodeButton.value = true;

                try {
                    const response = await apiPresenze.scanQrCodeCommessa(qrValue, dipendenteID, cliente, commessa);
                    if (response.data.status === 0) {
                        //error
                        openToast(response.data.txt, "toast_danger");
                    } else {
                        //Entrata o uscita timbrata correttamente
                        openToast(response.data.txt, "toast_success");
                        //Sto uscendo, devo aprire modale per creare il rapportino con i dati della presenza
                        if (response.data.tipo === "uscita") {
                            presenza.value = response.data.data;
                            openNuovoInterventoFromPresenza({ ...presenza.value }, { ...appuntamento.value });
                        }
                    }
                } catch (error) {
                    console.error(error);
                    openToast("Si è verificato un errore durante la scansione del QR", "toast_danger");
                } finally {
                    state.data = null;
                    showQrCode.value = false;
                    qrValue = null;
                    confirmationText.value = "";
                    disabledQrCodeButton.value = false;
                }
            } else {
                openToast("Inquadra il QR prima di salvare la presenza", "toast_danger");
            }
        }



        /*****************************************************
        *
        * ! Gestisce scansione con NFC per timbrare entrata o uscita
        *
        *****************************************************/
        // Stato reattivo per gestire le variabili dell'NFC
        const nfcState = reactive({
            scannedValue: null,   // Valore letto dal Tag NFC
            blockScan: false,     // Blocco della scansione per evitare letture multiple
            showContainer: false, // Visualizzazione della finestra di scansione NFC
        });

        // Chiude NFC container e reset state
        function chiudiNfcContainer() {
            nfcState.scannedNFCValue = null;
            nfcState.blockScan = true;
            nfcState.showNfcContainer = false;
        }

        // Check validità valore NFC letto 
        function isValidNfcValue(value) {
            return typeof value === 'string' && value.trim().length > 0;
        }

        // Funzione per gestire la timbratura tramite NFC
        async function handleTimbraturaNfc(nfc_value) {
            try {
                // Blocca ulteriori scansioni fino al completamento
                nfcState.blockScan = true;

                // Recupera informazioni sull'appuntamento
                const cliente = appuntamento.value.appuntamenti_cliente;
                const commessa = appuntamento.value.appuntamenti_impianto;

                // Effettua la chiamata API per la timbratura
                const response = await apiPresenze.timbraNFCCommessa(dipendenteID, nfc_value, cliente, commessa);
                //const response = await apiPresenze.timbraNFC(dipendenteID, reparto_id, cliente, commessa);

                // Verifica lo stato della risposta
                if (response.data.status === 0) {
                    openToast(response.data.txt, "toast_danger");
                } else if (response.data.status === 1) {
                    // Gestione della timbratura: ingresso o uscita
                    if (response.data.tipo === "entrata") {
                        chiudiNfcContainer();
                    } else {
                        chiudiNfcContainer();
                        presenza.value = response.data.data;
                        openNuovoInterventoFromPresenza(presenza.value, appuntamento.value);
                    }
                    openToast(response.data.txt, "toast_success");
                    nfcState.scannedValue = null;
                }
            } catch (error) {
                // Gestione del messaggio di errore
                /* let errorMessage = "Errore sconosciuto";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                openToast(`Si è verificato un errore durante la timbratura: ${errorMessage}`, "toast_danger"); */
                console.log(error)
                openToast("Si è verificato un errore durante la timbratura", "toast_danger");
            } finally {
                // Sblocca le scansioni alla fine del processo
                nfcState.blockScan = false;
            }
        }


        // Funzione per iniziare la scansione NFC
        let ndef = null; // Variabile per il lettore NFC
        const nfcScan = async () => {
            // Controlla se la commessa ha un reparto associato
            if (!appuntamento.value.projects_nfc_code) {
                openToast("Il codice NFC della commessa non è impostato, contatta l'assistenza per informazioni", "toast_danger");
                return;
            }

            // Inizializza lo stato della scansione
            nfcState.blockScan = false;
            nfcState.showContainer = true;

            try {
                // Inizializza lettore NFC e listener messaggi
                ndef = new NDEFReader();  // eslint-disable-line
                await ndef.scan();
                ndef.addEventListener("reading", processNfcMessage);
            } catch (error) {
                openToast("La scansione con NFC non è supportata dal dispositivo", "toast_danger");
                nfcState.showContainer = false;
                nfcState.blockScan = false;
            }
        };

        // Funzione per processare i messaggi NFC
        function processNfcMessage({ message }) {
            for (const record of message.records) {
                if (record.recordType === "text") {
                    const decoder = new TextDecoder(record.encoding);
                    const scannedValue = decoder.decode(record.data);

                    // Verifica se il valore scansionato è valido
                    if (isValidNfcValue(scannedValue) && !nfcState.blockScan) {
                        nfcState.scannedValue = scannedValue;
                        handleTimbraturaNfc(scannedValue);
                    }
                } else {
                    openToast("Non è stato possibile effettuare la scansione", "toast_danger");
                }
            }
        }


        // Funzione mock per simulare la lettura NFC
        /* function mockNfcScan() {
            console.log("Simulazione NFC attivata!");

            // Valore simulato del tag NFC
            const mockTagValue = "ZNr>bx(EC&mZ#-ue";

            // Simula un ritardo nella lettura per testare il flusso asincrono
            setTimeout(() => {
                console.log("Tag NFC simulato letto:", mockTagValue);

                // Verifica se il valore è valido
                if (isValidNfcValue(mockTagValue) && !nfcState.blockScan) {
                    nfcState.scannedValue = mockTagValue;

                    // Chiamata alla funzione di gestione della timbratura
                    handleTimbraturaNfc(mockTagValue);
                } else {
                    console.error("Errore: valore NFC simulato non valido o blocco attivo.");
                }
            }, 1000); // Ritardo di 1 secondo per simulare il tempo di scansione
        }
        mockNfcScan(); */


        /* async function handleTimbraturaNfc(reparto_id) {
            nfcState.blockScan = true;

            const cliente = appuntamento.value.appuntamenti_cliente;
            const commessa = appuntamento.value.appuntamenti_impianto;

            apiPresenze
                .timbraNFC(dipendenteID, reparto_id, cliente, commessa)
                .then((response) => {
                    //console.log(response);
                    if (response.data.status === 0) {
                        openToast(response.data.txt, "toast_danger");
                    } else if (response.data.status === 1) {
                        //Inserisco nuova presenza --> TIMBRO INGRESSO
                        if (response.data.tipo === "entrata") {
                            chiudiNfcContainer();
                        } else {
                            //Chiudo presenza e apro modale per inserimento rapportino --> TIMBRO USCITA
                            chiudiNfcContainer();
                            presenza.value = response.data.data;
                            openNuovoInterventoFromPresenza(presenza.value, appuntamento.value);
                        }
                        openToast(response.data.txt, "toast_success");
                        //blockScan.value = true;
                        nfcState.scannedNFCValue = null;
                    }
                })
                .catch((error) => {
                    //blockScan.value = true;
                    console.error(error);
                    openToast("Si è verificato un errore durante la timbratura", "toast_danger");
                });
        } */

        /* const nfcScan = async () => {
            if (!appuntamento.value.projects_nfc_code) {
                openToast("Il codice NFC della commessa non è impostato, contatta l'assistenza per informazioni", "toast_danger");
                return;
            }

            nfcState.blockScan = false;
            nfcState.showNfcContainer = true;

            try {
                // eslint-disable-line
                const ndef = new NDEFReader(); // eslint-disable-line
                await ndef.scan();
                
                ndef.addEventListener("reading", ({ message }) => {
                    const decoder = new TextDecoder();

                    for (const record of message.records) {
                        switch (record.recordType) {
                            case "text": {
                                const textDecoder = new TextDecoder(record.encoding);
                                nfcState.scannedNFCValue = textDecoder.decode(record.data);
                                //Se ho scansionato qualcosa e non sono in blocco procedo con la timbratura
                                if (nfcState.scannedNFCValue && !nfcState.blockScan) {
                                    handleTimbraturaNfc(nfcState.scannedNFCValue);
                                }
                                break;
                            }
                            default:
                                openToast("Non è stato possibile effettuare la scansione", "toast_danger");
                        }
                    }
                });
            } catch (error) {
                openToast("La scansione con NFC non è supportata", "toast_danger");
                console.log(error);
                nfcState.showNfcContainer = false;
                nfcState.blockScan = false;
            }
        }; */



        /*******************************************************
         * 
         * 
         * ! Gestione timbratura con GPS
         * 
         * 
         ******************************************************/
        const geoOptions = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
         };
        
        const geoNotAllowed = ref(false);
        const sendingCoordinates = ref(false);

        async function getPosition(options) {
            return new Promise((resolve, reject) =>
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        // User granted location
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;
                        geoNotAllowed.value = false;

                        return resolve(position);
                    },
                    function(error) {
                        //console.log(error);
                        if (error.code === error.PERMISSION_DENIED) {
                            sendingCoordinates.value = false;
                            //openToast("Geolocalizzazione spenta oppure hai negato l'autorizzazione.", "toast_danger");
                        } else {
                            // Si è verificato un altro tipo di errore durante la geolocalizzazione
                            sendingCoordinates.value = false;
                            //openToast("Si è verificato un errore durante la geolocalizzazione.", "toast_danger");
                        }
                        return reject(error);
                    }
                )
            );
        }

        const buttonText = ref("Timbra entrata con GPS");
        function checkButtonText() {
            //Se ho timbrato entrata mostro "Timbra uscita con GPS"
            const presenzaAppuntamento = localStorage.getItem("appuntamentoPresenza");
            //console.log(presenzaAppuntamento);
            if (presenzaAppuntamento && presenzaAppuntamento == appuntamento.value.appuntamenti_id) {
                buttonText.value = "Timbra uscita con GPS";
            } else {
                buttonText.value = "Timbra entrata con GPS";
            }
        }
        
        async function timbraGPS() {
            sendingCoordinates.value = true;

            let pos = null;
            try {
                pos = await getPosition(geoOptions);
                //console.log(pos);
                
            } catch (error) {
                geoNotAllowed.value = true;
                if (error.code === error.PERMISSION_DENIED) {
                    openToast("Geolocalizzazione spenta oppure hai negato l'autorizzazione.", "toast_danger");
                    return;
                } else {
                    openToast("Si è verificato un errore durante la geolocalizzazione.", "toast_danger");
                    return;
                }
            }            

            if (pos && pos.coords) {
                // Se non ho voce nel locastorage sto timbrando entrata
                let tipologia = null;
                if(localStorage.getItem("appuntamentoPresenza")) {
                    tipologia = 'uscita';
                } else {
                    tipologia = 'entrata';
                }
                
                //const data = moment().format("YYYY-MM-DD");
                const orario = moment().format("HH:mm");

                try {
                    const response = await apiPresenze.timbraCoordinate(
                        dipendenteID,
                        orario,
                        appuntamento.value.projects_reparto, //reparto
                        appuntamento.value.appuntamenti_cliente, //cliente
                        appuntamento.value.appuntamenti_impianto, //commessa
                        pos.coords.latitude,
                        pos.coords.longitude,
                        tipologia,
                        "APP - Appuntamento GPS"
                    );
                    if (response.data.status === 0) {
                        openToast(response.data.txt, "toast_danger");
                        sendingCoordinates.value = false;
                        pos = null;
                    } else if (response.data.status === 1) {
                        checkButtonText();

                        sendingCoordinates.value = false;
                        openToast(response.data.txt, "toast_success");
                        //Chiudo presenza e apro modale per inserimento rapportino --> TIMBRO USCITA
                        if (response.data.tipo === "uscita") {
                            //Rimuovo localstorage per sapere se ho timbrato presenza associata a questo appuntamento e cambio label
                            localStorage.removeItem("appuntamentoPresenza");

                            presenza.value = response.data.data;
                            openNuovoInterventoFromPresenza(presenza.value, appuntamento.value);
                        } else {
                            sendingCoordinates.value = false;
                            pos = null;
                            //Imposto localstorage per sapere se ho timbrato presenza associata a questo appuntamento
                            localStorage.setItem("appuntamentoPresenza", appuntamento.value.appuntamenti_id);
                            buttonText.value = "Timbra uscita con GPS";
                        }
                    }
                } catch (error) {
                    //console.error(error);
                    sendingCoordinates.value = false;
                    pos = null;
                    openToast("Prima di timbrare devi permettere e/o attivare la geolocalizzazione", "toast_danger");
                    return;
                }
            } else if (!pos) {
                sendingCoordinates.value = false;
                openToast("Prima di timbrare devi permettere e/o attivare la geolocalizzazione", "toast_danger");
                return;
            }
        }


        /*********************************************************
         *
         * ! Controllo se ho record nel localstorage, se sì vuol dire che non ho compilato subito il rapportino collegato
         * ! Se il record è per questo appuntamento allora devo aprire la modale per compilare il rapportino
         *
         ********************************************************/
        function getLocalPresenza() {
            const presenza_rapportino = JSON.parse(localStorage.getItem("presenza_rapportino"));

            if (presenza_rapportino && Object.keys(presenza_rapportino).length !== 0) {
                if (appuntamento.value.appuntamenti_id === presenza_rapportino.appuntamento.appuntamenti_id) {
                    // Ho presenza chiusa ma non ho compilato il rapportino collegato perchè altrimenti avrei svuotato il dato nel localstorage
                    // Devo comunque nascondere i pulsanti perchè finchè non creo il rapportino dalla chiusura presenza la pagina continauerà ad aprirsi (giustamente)
                    rapportinoDaPresenza.value = true;
                    openNuovoInterventoFromPresenza(presenza_rapportino.presenza, presenza_rapportino.appuntamento);
                }
            }
        }

        /**
         * 
         * ! Controllo se la data dell'appuntamento visualizzato è oggi per consentire la timbratura
         * 
         */
        const isAppointmentToday  =ref(false);
        function checkAppointmentDate() {
            const appointmentDate = appuntamento.value.appuntamenti_giorno;

            if (!appointmentDate) {
                isAppointmentToday.value = false;
                return;
            }

            const today = moment().format('YYYY-MM-DD');
            const appointmentDateFormatted = moment(appointmentDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');

            isAppointmentToday.value = appointmentDateFormatted === today;
        }


        onMounted(() => {
            // Controllo se appuntamento è oggi per consentire presenza
            checkAppointmentDate();
            // Controllo presenza collegata a rapportino
            getLocalPresenza();
            checkButtonText();
            // Extra data
            loadProducts();
            loadServices();
            loadAttachments();
        });

        return {
            loading,
            dateFormat,
            arrowBackOutline,
            closeModal,
            arrowForwardCircle,
            appuntamento,
            dateTimeFormat,
            newRapportino,
            riferimentoCliente,
            rapportinoCreato,
            ordinaMateriali,
            // Prodotti & servizi
            openProductDetail,
            products,
            services,
            // Attachments
            attachments,
            setAttachmentUrl,
            lightboxOpen,
            currentIndex,
            currentImageUrl,
            openLightbox,
            closeLightbox,
            prevImage,
            nextImage,
            isImage,
            currentAttachmentFiles,
            // GPS
            isAppointmentToday,
            geoNotAllowed,
            buttonText,
            timbraGPS,
            sendingCoordinates,
            rapportinoDaPresenza,
            chiusoSenzaCreazionePresenza,
            generateMapsLink,
            //NFC
            nfcState,
            nfcScan,
            //QR Code
            openQrCode,
            showQrCode,
            QrStream,
            ...toRefs(state),
            onDecode,
            chiudiQrCode,
            handleQrCodeTimbratura,
            confirmationText,
            disabledQrCodeButton,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-content {
    --background: #f2f2f2;
}
ion-button {
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-primary {
    background: var(--ion-color-primary, #3880ff) !important;
    padding: 8px;
    color: #ffffff;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f2f2f2;
}

/* lead v2*/
.data_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    border-radius: 8px;
    /*box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);*/
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px #00000040;
}
.data_container .appuntamento_title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #1d1d1b;
    margin-bottom: 16px;
}
.data_container .city {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.data_container .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
    border-bottom: 0.5px solid #7676803d;
}
.data_container .field_list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px 0px;
    border-bottom: 0.5px solid #7676803d;
}
.data_container .field_list .title {
    margin-bottom: 4px;
}
.data_container .field_list .persone,
.data_container .field_list .automezzi {
    font-size: 14px;
}
.data_container .field_description {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    border-bottom: none;
}
.data_container .field_description .title {
    width: 100%;
    margin-bottom: 4px;
}
.data_container .field:last-of-type {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 0px;
    border-bottom: none;
}
.data_container .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
    border-bottom: none;
}

.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #1d1d1b;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}
.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.container_button {
    width: 100%;
    margin-top: 12px;
}
.btn_action {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    background-color: rgb(219 234 254);
    color: rgb(37 99 235);
}
.btn_action:disabled {
    opacity: .4;
    cursor: not-allowed;
}

.partecipazione {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 500;
    color: #475569;
    text-align: center;
}

.existing_rapportino {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-top: 12px;
    text-align: center;
    border-radius: 12px;
    font-weight: 600;
    background-color: rgb(187 247 208);
    color: rgb(21 128 61);
}

.products_container,
.services_container {
    margin-top: 16px;
    max-height: 300px;
    overflow-y: scroll;
}
.products_container .title,
.services_container .title {
    font-size: 16px;
    font-weight: 600;
    color: #1d1d1b;
    margin-bottom: 8px;
}
.single_prodotto {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.single_service {
    width: 100%;
    margin-bottom: 8px;
}
.box_services {
    width: 100%;
}

.mobile_link {
    color: #086fa3;
    text-decoration: none;
}

.attachment_title {
    font-size: 14px;
    font-weight: 500;
    color: #1d1d1b;
}
.single_attachment {
    width: 100%;
    margin-bottom: 12px;
}
.attachments_file_container {
    display: flex;
    gap: 8px;
    overflow-x: scroll;
    min-height: 48px;
    padding-top: 8px;
}
.attachment_file {
    font-size: 14px;
}
.preview_image {
    max-width: 100px;
    cursor: pointer;
    margin: 5px;
    border-radius: 4px;
}
.lightbox_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 0px 16px;
}

.lightbox_content {
    position: relative;
    text-align: center;
}

.lightbox_image {
    max-width: 100%;
    max-height: 90%;
    border-radius: 8px;
}

.lightbox_controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.lightbox_controls button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #086fa3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.lightbox_controls button:disabled {
    background-color: #b1b1b1;
    cursor: not-allowed;
}

.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.3s;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}

.lightbox_controls button.close_lightbox {
    background-color: #f6a1a1;
    color: #a30808;
}

.info_box {
    padding: 10px;
    border-radius: 10px;
    font-weight: 500;
    background: rgb(241 245 249);
    color: rgb(71 85 105);
}


/** MODALE QR CODE */

.modal_straordinario {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 16px;
    z-index: 10;
}
.input_container {
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
}
.input_container .field {
    margin-bottom: 24px;
}
.input_container .title {
    font-weight: 500;
    margin-bottom: 12px;
}
.input_container .title_qr {
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
}
.input_container .confirmation_text {
    margin-top: 16px;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: center;
}

.btn_scan_qrcode {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    background-color: rgb(219 234 254);
    color: rgb(37 99 235);
    margin-bottom: 20px;
}
.btn_scan_qrcode:disabled {
    /* background-color: rgba(10, 144, 212, 0.5);
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1); */
    opacity: 0.75;
    transition: all 0.15s ease-in;
}
.btn_close_scan_qrcode {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    color: rgb(220 38 38);
    background-color: rgb(254 226 226);
}
</style>
